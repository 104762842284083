@import "../../assets/scss/colors";
.loader-teach-css {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  z-index: 1000000!important;
  align-items: center;
  //background: rgba(31, 49, 60, 0.85) !important;
  background: $dimmer!important;

  .ui-dimmer_ {
    background: $teach-black!important;
  }
  .loader-wrapper {
    display: flex;
    //height: 45px;
    background: white;
    //width: 165px;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    box-shadow: 0 1px 25px rgba(50, 50, 93, 0.1), 0 1px 10px rgba(0, 0, 0, 0.07) !important;
    border-radius: 4px;
  }
  .loader-main,span {
    height: 30px;
    display: flex;
    align-items: center;
    color: $teach-black;
    font-size: 15px;
  }
  span {
    margin-right: 10px;
  }
  .loader {
    border: 2px solid transparent;
    border-radius: 50%;
    //border-top: 3px solid $orange;
    border-top: 2px solid $teach-black;
    width: 20px;
    height: 20px;
    -webkit-animation: spinner_teachme 0.7s linear infinite; /* Safari */
    animation: spinner_teachme 0.7s linear infinite;
  }

  /* Safari */
  @-webkit-keyframes spinner_teachme {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spinner_teachme {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}
