@font-face {font-family: "Gilroy Bold";
  src: url("../fonts/DM_Sans/DMSans-Bold.ttf"); /* IE9*/
  //src: url("src/assets/fonts/DM_Sans/DMSans-Bold.ttf") format("opentype"); /* IE6-IE8 */
}
@font-face {font-family: "Gilroy Medium";
  src: url("../fonts/DM_Sans/DMSans-Medium.ttf"); /* IE9*/
  //src: url("../fonts/DM_Sans/DMSans-Medium.ttf") format("opentype"); /* IE6-IE8 */
}
@font-face {font-family: "Gilroy Medium";
  src: url("../fonts/DM_Sans/DMSans-Medium.ttf"); /* IE9*/
  //src: url("../fonts/DM_Sans/DMSans-Medium.ttf") format("opentype"); /* IE6-IE8 */
}
@font-face {font-family: "Gilroy Light";
  src: url("../fonts/DM_Sans/DMSans-Regular.ttf"); /* IE9*/
  //src: url("../fonts/DM_Sans/DMSans-Regular.ttf") format("opentype"); /* IE6-IE8 */
}

//$common-fonts:      'Fira Sans', sans-serif!important;
//$common-fonts:      "Red Hat Display" !important;
$common-fonts:      "Gilroy Medium"!important;
